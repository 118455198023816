import React from "react";
import Layout from "../component/layout";
import ServicesHomeComponentV2 from "../component/servicesHomeComponentV2";
import WebsLogoSwiper from "../component/websLogoSwiper";
import ItExpandTeamComponent from "../component/Services/IT/ItExpandTeamComponent";
import TimelineV1Component from "../component/TimelineV1Component";
import BorderCards from "../component/BorderCards";
import TechStackComponent from "../component/Services/techStackComponent";
import CustomDevelopmentVideoComponent from "../component/Services/CustomDevelopment/customDevelopmentVideoComponent";
import FooterComponent from "../component/footerComponent";
import AccordionComponentV2 from "../component/accordionComponentV2";
import HomeImage from "../images/service-angular-dev-image.png";

const ServiceAngularDev = () => {
  const seo = {
    title: "Angular Development Outsourcing Service | Motomtech",
    metaDesc:
      "Need expert Angular developers for your project? Explore the advantages of outsourcing Angular development and find the right team to bring your ideas to life.",
  };
  const questions = [
    {
      hr: true,
      title: "What are the benefits of Angular Outsourcing?",
      description:
        "Outsourcing Angular development can yield multiple benefits. Firstly, it gives you access to a vast pool of expert developers with deep knowledge of the Angular framework, allowing you to create high-quality, robust, and scalable applications. Secondly, it's cost-effective. With outsourcing, you only pay for the development work done, eliminating the need for hiring, training, and retaining an in-house team. Additionally, outsourcing can expedite project delivery. Experienced outsourcing teams have refined processes and tools in place to manage projects efficiently and deliver them on time. Finally, it allows you to focus on your core business operations while a specialized team takes care of your software development needs.",
    },
    {
      hr: true,
      title: "How much do your outsourcing angular solutions cost?",
      description:
        "The cost of our Angular outsourcing solutions varies based on several factors such as the complexity and size of the project, the specific features and functionality required, and the timeline for project completion. We provide a transparent pricing model and offer competitive rates. To get an accurate cost estimate, we encourage you to contact us with your project details so we can understand your requirements and provide a tailored quote.",
    },
    {
      hr: false,
      title: "Angular vs React: Which is Better?",
      description:
        "Both Angular and React are robust and popular frameworks for web application development, each with its unique strengths. The choice between the two often depends on your project's specific requirements. Angular is a complete, full-featured framework, excellent for building large-scale, complex applications. It provides a structured approach to development, which can be beneficial for larger teams and projects. On the other hand, React is a library that focuses on building user interfaces. It's known for its flexibility and performance, especially in the view layer of an application. It's ideal for projects where you want the flexibility to choose other technologies for aspects like routing or state management. Therefore, both Angular and React are great choices, but the decision ultimately depends on your project's unique needs.",
    },
  ];
  return (
    <div>
      <Layout seo={seo} title={seo.title}>
        <ServicesHomeComponentV2
          homeTitle={"Angular Development Outsourcing"}
          homeDescription={
            "At Motomtech, our rich experience and unparalleled expertise in Angular development position us as the go-to solution for all your development needs. Our dedicated team of highly skilled Angular developers are well-versed with the latest practices, bringing in-depth knowledge and a proven track record to every project. Choosing Motomtech means securing success for your project, as we leverage our industry-leading expertise to turn your ideas into reality."
          }
          homeImage={HomeImage}
          homeButton={"Request our services"}
          isGatsby={false}
        />
        <WebsLogoSwiper />
        <ItExpandTeamComponent
          title={
            "Outsource Your Angular Development Needs with Our Fully Customizable Angular Development Services"
          }
          description1={
            "Choosing the right partner for your Angular development needs can significantly influence the success of your project. That's where Motomtech comes in - we specialize in Angular development, providing fully customizable services tailored to fit your specific requirements. Our team is dedicated to designing and developing robust, scalable, and efficient applications that not only meet but exceed your expectations. Leveraging our services means gaining access to cutting-edge technology and industry-leading expertise, delivering a solution that aligns perfectly with your business goals"
          }
          description2={
            "With years of experience in the tech industry, Motomtech has built a strong reputation as a reliable and innovative Angular development partner. Our portfolio boasts a myriad of successful projects across diverse sectors, providing us with a deep understanding of industry-specific requirements and challenges. Our extensive hands-on experience enables us to deliver solutions that are not only technologically advanced but also business-centric. We understand that every business is unique, and we know how to leverage our technological expertise to create solutions that add value and drive growth for your business"
          }
          description3={
            "Furthermore, our team of Angular developers are not just coders; they're problem solvers dedicated to innovation. Our developers have been trained in the latest Angular development practices and are always up-to-date with emerging trends. This ensures that we deliver products that are future-proof and capable of adapting to changing market dynamics. Our team's wide-ranging experience and adaptive skills translate into high-quality applications that enhance your business's digital presence."
          }
          description4={
            "Choosing Motomtech as your Angular development partner also means you're opting for a seamless and efficient project management experience. We understand the importance of clear communication, so we ensure you're always informed about your project's progress. Our agile methodology allows for flexibility and adaptability, ensuring the project’s end product aligns with your vision and expectations."
          }
          description5={
            "Trust in our experience and expertise in Angular development. With Motomtech, you're choosing a partner committed to delivering outstanding results, fostering your business growth, and taking your digital presence to the next level. Choose us for your Angular development needs, and we will turn your ideas into a reality that outperforms your competition."
          }
          description6={""}
        />
        <TimelineV1Component
          timelineTitle={"Angular Development Services"}
          timelineTextTitle1={"Custom Angular Development:"}
          timelineTextDescription1={
            "At Motomtech, we specialize in custom Angular development, crafting tailored applications that precisely meet your unique business requirements. Our expertise spans the entire Angular ecosystem, enabling us to design and build scalable, robust, and highly efficient applications that enhance your business's digital prowess."
          }
          timelineTextTitle2={"Angular IT Augmentation Services:"}
          timelineTextDescription2={
            "Boost your in-house team's capacity and skills with our Angular IT augmentation services. We offer expert Angular developers who can seamlessly integrate with your existing team, bringing additional expertise and industry knowledge to accelerate development and optimize productivity."
          }
          timelineTextTitle3={"Angular QA Services:"}
          timelineTextDescription3={
            "Our Angular QA services are designed to ensure the delivery of high-quality, bug-free applications. Our dedicated team of quality assurance experts uses state-of-the-art testing tools and methodologies, ensuring every Angular application we deliver meets the highest standards of quality, performance, and reliability."
          }
          timelineTextTitle4={"Angular Maintenance:"}
          timelineTextDescription4={
            "Stay ahead in the constantly evolving digital landscape with our comprehensive Angular maintenance services. We provide regular updates, performance monitoring, bug fixing, and continuous improvements to ensure your Angular applications remain relevant, efficient, and effective in serving your business needs."
          }
          timelineTextTitle5={"Angular Consulting:"}
          timelineTextDescription5={
            "Looking for expert guidance on your Angular projects? Our Angular consulting services are designed to provide strategic insights and recommendations. We can assist with architectural decisions, performance optimization strategies, and best practices to ensure your Angular project's success from inception to launch and beyond."
          }
        />
        <ItExpandTeamComponent
          title={"Why Leverage Angular For Your Next Project"}
          description1={
            " Angular is one of the most powerful and widely used frameworks for building web applications, and with good reason. Developed and maintained by Google, Angular provides a robust and reliable platform that facilitates the development of high-performance web applications. Choosing Angular for your next project comes with several significant advantages."
          }
          description2={
            "Firstly, Angular is a comprehensive, full-featured framework. Unlike some other JavaScript libraries or frameworks that handle only certain aspects of application development, Angular provides a complete solution. It handles everything from data binding and dependency injection to routing and form validation. This comprehensive nature reduces the need for additional libraries, making your project more manageable and less complicated."
          }
          description3={
            "Additionally, Angular is designed for scalability. It excels at building large-scale applications that need to handle substantial amounts of data or traffic. This is why many enterprise-level organizations choose Angular for their web application needs. Whether your project is a small single-page app or a complex enterprise-level system, Angular's modularity and efficient data handling capabilities make it a sound choice. "
          }
          description4={
            "One of the other key advantages of Angular is its strong community support. Since Angular is developed by Google, it has a vast ecosystem of developers and enthusiasts who contribute to its development and improvement. This means you have access to a wealth of resources, including libraries, tools, tutorials, and forums, to assist with any challenges you may face during development."
          }
          description5={
            "Finally, Angular also embraces modern web standards and technologies, such as responsive design and progressive web apps (PWAs). These features enable Angular applications to deliver a high-quality user experience on a wide range of devices and platforms. If you are looking to build an app that not only functions well but also delivers a smooth and engaging user experience, Angular is an excellent choice."
          }
          description6={
            "In conclusion, Angular's comprehensive feature set, scalability, strong community support, and commitment to modern web standards make it an outstanding choice for your next project. Whether you're looking to build a single-page app or a large, complex system, Angular has the tools and capabilities to make your project a success."
          }
        />
        <div
          style={{
            background:
              "linear-gradient(108.34deg, #09062D -2.63%, #36498C 100%)",
          }}
        >
          <p className="section-container-title">
            Angular Development Solutions
          </p>

          <BorderCards
            borderCardsText1={"Mean Stack Development:"}
            borderCardsText2={
              "With Angular, you can harness the power of MEAN Stack Development, an acronym for MongoDB, Express.js, Angular.js, and Node.js. This technology stack is a full-stack JavaScript solution that helps you build fast, robust, and maintainable web applications. Its unified language reduces unnecessary complexity, streamlines development, and accelerates time to market."
            }
            borderCardsText3={"Interactive Project Prototypes:"}
            borderCardsText4={
              "Angular is an ideal choice for creating interactive project prototypes. It helps to visualize the final product before full-scale development begins. Using Angular's data binding and dependency injection, you can easily create interactive, responsive, and high-fidelity prototypes that can expedite stakeholder approval and user feedback collection."
            }
            borderCardsText5={"Simplified UX/UI Design:"}
            borderCardsText6={
              "Angular provides a simplified approach to UX/UI design. With its component-based architecture, you can develop reusable UI components that ensure consistency across your application. Additionally, Angular's rich set of tools and libraries enable rapid development of intuitive, engaging, and user-friendly interfaces, significantly enhancing the user experience."
            }
            borderCardsText7={"Progressive Web Apps (PWAs):"}
            borderCardsText8={
              "Angular's built-in support for Progressive Web Apps (PWAs) enables you to deliver a mobile-app-like experience right in the browser. PWAs built with Angular are highly responsive, reliable, and can work offline or on low-quality networks. They also offer features like push notifications, home screen icons, and full-screen mode, enhancing user engagement and satisfaction."
            }
            borderCardsText9={"Native and Hybrid Mobile Apps:"}
            borderCardsText10={
              "Angular is a perfect choice for developing both Native and Hybrid Mobile Apps. Using Angular with technologies like Ionic and NativeScript, you can build high-performance mobile apps with near-native performance. These apps not only provide a seamless user experience but are also efficient to build and maintain, as they share the same codebase across different platforms."
            }
            borderCardsText11={"Single Page Applications (SPAs):"}
            borderCardsText12={
              "Angular is widely recognized for its ability to create highly responsive Single Page Applications (SPAs). SPAs built with Angular load a single HTML page and dynamically update it as the user interacts with the app, providing a smooth, desktop-app-like user experience. This can significantly improve the application's performance and user satisfaction."
            }
          />
        </div>
        <TechStackComponent />
        <CustomDevelopmentVideoComponent />
        <AccordionComponentV2
          title={"Frequently Asked Questions"}
          questions={questions}
        />
        <FooterComponent />
      </Layout>
    </div>
  );
};

export default ServiceAngularDev;
